.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Optional: Center align vertically */
    height: 60vh; /* Adjust height as needed */
    /* width: 400px; Adjust width as needed */
    margin: 0 auto; /* Center horizontally */
    padding: 20px; /* Optional: Add padding */
  }

.login-container input{
    font-size: 1.7rem; /* Example: Larger font size */
}

.login-container button{
    font-size: 1rem; /* Example: Larger font size */
    padding: 5px, 5px, 10px, 10px; /* Example: Larger padding */
    margin-bottom: 10px; /* Example: Increased bottom margin */
}