.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header {
  align-items: center;
  padding: 5px 10px;
  height: 80px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.machine-model-container{

 padding:10px;


}

.quantity-low {
  color: red; /* Light red background */
}

.rotate-header {
  display: flex; 
  height: 100%;   
  /* transform: rotate(-90deg);  */
  text-align: center;
  align-content: center;
  align-items: center;
  /* transform-origin: center;  */
  /* white-space:nowrap;  */
  color: rgb(0, 0, 0);
  font-weight:bold;
  font-size:large;
  /* border-style:groove;   */
}

/* App.css */

.light-mode {
  background-color: white;
  color: black;
}

.dark-mode {
  background-color: #121212;
  color: white;
}

/* Additional styles for your app can go here */

