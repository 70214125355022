/* styles.css */
.urgent {
    color: rgb(255, 115, 0);
}

.demo {
    color: rgb(29, 110, 231);
}

.broken {
    color: rgb(255, 0, 0);
}
  
  