.aftertsale_table {
    width: 100%;
    border-collapse: collapse; 
}
.aftersale_th,.aftersale_td {
    border: 1px solid #ddd; 
    padding: 8px; 
    text-align: left; 
}
.aftersale_tr:nth-child(even) {
    background-color: #f2f2f2; 
}
.aftersale_tr:nth-child(odd) {
    background-color: #ffffff; 
}
.aftersale_th {
    background-color: #4CAF50; 
    color: white; 
}